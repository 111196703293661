<template>
	<form type="POST" v-on:submit.prevent="storeCelebraciones()" ref="celebracionesForm">
		<h3 class="mb-3 text-primary">
			<font-awesome-icon :icon="faPlus" /> {{title}}
		</h3>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 mb-3">
						<label for="nombre">Nombre de la celebración:</label>
						<input type="text" name="nombre" id="nombre" class="form-control" placeholder="Ingrese nombre" v-model="form.NOMBRE_CELEBRACION" maxlength="150" required>
						<span v-if="errors.NOMBRE_CELEBRACION" class="text-danger">
							{{errors.NOMBRE_CELEBRACION[0]}}
						</span>
					</div>

					<div class="col-lg-12 mb-3">
						<label for="descripcion">Descripción:</label>
						<textarea name="descripcion" id="descripcion" class="form-control" placeholder="Ingrese descripción" v-model="form.DESCRIPCION_CELEBRACION" maxlength="750" required></textarea>
						<span v-if="errors.DESCRIPCION_CELEBRACION" class="text-danger">
							{{errors.DESCRIPCION_CELEBRACION[0]}}
						</span>
					</div>

					<div class="col-lg-12 mb-3">
						<label for="adjunto">Fotos:</label>
						<UploadFile id="adjuntos" tipo="celebraciones" v-model="form.adjuntos" :adjuntos="adjuntos" :limitFile="5" accept="image/*" />
						<span v-if="errors.adjuntos" class="text-danger">
							{{errors.adjuntos[0]}}
						</span>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-end align-items-center">
					<div class="me-2">
						<router-link :to="{name: 'admin.celebraciones.index'}" class="btn btn-link">
							Cancelar
						</router-link>
					</div>
					<div>
						<div class="btn-group">
							<button type="submit" class="btn btn-primary" :disabled="loading || !validateForm">
								<BtnLoading v-if="loadingCelebraciones " />
								<span v-else>
									<font-awesome-icon :icon="faSave" /> Crear celebración
								</span>
							</button>
							<button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" :disabled="loading || !validateForm">
								<span class="visually-hidden">Más opciones</span>
							</button>
							<ul class="dropdown-menu dropdown-menu-end">
								<li>
									<a class="dropdown-item" href="#" v-on:click.prevent="storeCelebraciones(true)">Crear y nuevo</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faSave, faPlus } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	import UploadFile from '@/components/UploadFile.vue'
	export default {
		components: {
			FontAwesomeIcon,
			BtnLoading,
			UploadFile
		},
		data(){
			return {
				faSave,
				faPlus,
				form: {
					NOMBRE_CELEBRACION: '',
					DESCRIPCION_CELEBRACION: '',
					adjuntos: []
				},
				adjuntos: [],
				loadingCelebraciones: false,
				validateForm: false
			}
		},
		methods: {
			storeCelebraciones(newItems=false){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingCelebraciones = true
				this.$store.dispatch('storeCelebraciones', this.form).then((res) => {
					this.$toast.info(`La celebración ha sido creada`)
					if (newItems) {
						this.form.NOMBRE_CELEBRACION = ''
						this.form.DESCRIPCION_CELEBRACION = ''
						this.form.adjuntos = []
						this.adjuntos = []
					}else{
						this.$router.push({name: 'admin.celebraciones.index'})
					}
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Error al crear registro')
						}
					}
				}).then(() => {
					this.loadingCelebraciones = false
				})
			},
			checkValidity(){
				if (!this.$refs.celebracionesForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.celebracionesForm.checkValidity()
				}
			},
		},

		computed:{
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			loading() {
				return this.$store.getters.loading
			},

			errors() {
				return this.$store.getters.errors || {}
			},
			nombre(){
				return this.form.NOMBRE_CELEBRACION
			}
		},
		watch: {
			nombre(val, oldVal){
				var nombre = (val.split(' ').join('_') || '').toLowerCase().substring(0, 150)
				nombre = nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
				
				var nombreOld = (oldVal.split(' ').join('_') || '').toLowerCase().substring(0, this.maxName)
				nombreOld = nombreOld.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
				
				if(this.form.SLUG == `${nombreOld || ''}` || this.form.SLUG == ''){
					this.form.SLUG = `${nombre || ''}`
				}
			},
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>